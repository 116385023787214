import React from 'react';
import styled from 'styled-components';

import Icon from '../icon';
import appleTVImg from '../../../images/parceiros/appletv.svg';
import amazonPrimeImage from '../../../images/parceiros/amazon-prime.png';
import disneyPlysImage from '../../../images/parceiros/disney-logo.png';

const amazonPrimeSkus = [];

const disneyPlusSkus = ['TNF0949', 'TNF0955', 'TNF0960', 'TNF0968', 'TPTF949', 'TPTF955', 'TPTF959', 'TPTF967', 'TPTF975'];

const CardFooter = ({ plan, pageName }) => {
  const svas = ['amazon-prime', 'hbogo', 'youtube', 'disney'];

  const positionVas = (vas) =>{
    return vas.sort((a, b) => a.position - b.position);
  };

  const isDisneyPlus = disneyPlusSkus.includes(plan.sku.substring(0, 7))

  return (
    <>
      <Footer>
        <>
          {isDisneyPlus && (
            <AppsWrapper>
              <Plus>+</Plus>
              <img src={disneyPlysImage} style={{ paddingBottom: '12px'}} alt="disney logo" />

              <p style={{ marginTop: '6px',marginLeft: '8px'}}>Assinatura inclusa *</p>
            </AppsWrapper>
          )}
          {plan.acumulateInternet && (
            <FlashSalesWrapper>
              <Plus>+</Plus>
              <GBAnimated>
                <p>GB</p>
              </GBAnimated>
              <FlashSalesText>Internet Acumulada</FlashSalesText>
            </FlashSalesWrapper>
          )}

          {plan.appletv && (
            <AppsWrapper>
              <Plus>+</Plus>
              <img
                src={appleTVImg}
                alt=""
                style={{
                  paddingBottom: 8,
                  marginRight: 10,
                }}
              />
              <p>Assinatura inclusa</p>
            </AppsWrapper>
          )}

          {amazonPrimeSkus.includes(plan.sku.substring(0, 7)) && (
            <AppsWrapper>
              <Plus>+</Plus>
              <img src={amazonPrimeImage} alt="amazon logo" />
              <p>por 12 meses</p>
            </AppsWrapper>
          )}

          <AppsWrapper>
            <Plus>+</Plus>
            <AppList>
              {plan.unlimitedApps.map((app, index) => (
                <App isBigger key={index}>
                  <Icon className="card-icon" id={'icon-' + app} />
                </App>
              ))}
            </AppList>
          </AppsWrapper>

          {plan.sku.substr(0, 7) === plan.planC && (
            <>
              <IncludedSubscriptionsSva />
              <SubscriptionsText>Liberdade para escolher:</SubscriptionsText>
              <AppList>
                {svas.map((app, index) => (
                  <AppSva key={index} style={{ width: '60px'}} >
                    <Icon id={'icon-' + app} alt={app.replace('-', ' ')} extension={'svg'} sprite={false} style={{ width: '64px'}} />
                  </AppSva>
                ))}
              </AppList>
            </>
          )}
          {plan?.vas && (
            <IncludedSubscriptions>
              <SubscriptionsText>Assinaturas inclusas:</SubscriptionsText>
              <AppList>
                {positionVas(plan.vas).map((vas, index) => (
                  <App isBigger key={index}>
                    <img src={vas.imageUrl} alt={vas.text} />
                  </App>
                ))}
              </AppList>
            </IncludedSubscriptions>
          )}

          <MoreInfo>
            {plan.passaportAmericas && (
              <>
                <strong>{plan.passaportAmericas}</strong> {`de Roaming Internacional
                nas Américas `}
              </>
            )}
              + Ligações e SMS ilimitados
          </MoreInfo>
          {isDisneyPlus && (
            <MoreInfo>* Assinatura do Disney+ contém anúncios.</MoreInfo>
          )}
        </>
      </Footer>

      {plan.isLight && pageName !== 'light' && <Bar />}
    </>
  );
};

const Text = styled.p`
  color: ${({ theme }) => theme.palette.primary};
  font-size: 14px;
`;

const AppsWrapper = styled.div`
  opacity: ${({ isTransparency }) => (isTransparency ? '0.2' : '1')};
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
  > ${Text} {
    margin: 0 0 5px 0;
  }

  p {
    font-size: 13px;
    line-height: 18.97px;
    color: #4a4a4a;
    margin-bottom: 2px;
  }

  img {
    width: 80px;
  }
`;

export const GBAnimated = styled.div`
  margin: 0 10px 0 5px;
  position: relative;
  &:after {
    content: '';
    display: block;
    background: url('/images/circle-arrow.svg') no-repeat center;
    background-size: 30px;
    position: absolute;
    top: -11px;
    left: -8px;
    width: 40px;
    height: 40px;
    z-index: 1;
    animation: infinite-spinning 1s infinite;
  }
  p {
    font-size: 16px;
    font-weight: bold;
    color: #23507a;
    z-index: 3;
  }
  @keyframes infinite-spinning {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const AppList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: ${(props) => props.noVideoPartners && '61px'};
  gap: 8px;

  .card-icon {
    width: 30px;
    height: 30px;
  }
`;

const Footer = styled.footer`
  align-items: flex-start;
  background: #f2f2f2;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
  flex: 1 0 auto;
`;

const IncludedSubscriptions = styled.div`
  margin: 20px 0;
`;

const IncludedSubscriptionsSva = styled.div`
  margin-top: 20px;
`;

const SubscriptionsText = styled(Text)`
  color: #4a4a4a;
  font-size: 14px;
  margin-bottom: 6px;
`;

const MoreInfo = styled(SubscriptionsText)`
  text-align: left;
  & + p {
    letter-spacing: -0.63px;
    margin: 10px 0 0;
  }
`;

const Plus = styled.span`
  color: ${({ theme }) => theme.palette.secondary};
  font-weight: bold;
  font-size: 32px;
  display: inline-block;
  margin-right: 5px;
  line-height: 1;
`;

const FlashSalesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Bar = styled.div`
  background: #f2f2f2;
  height: 43px;
`;

const FlashSalesText = styled(Text)`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
`;

const App = styled.li`
  width: ${(p) => (p.isBigger ? '30px' : '23px')};
  height: ${(p) => (p.isBigger ? '30px' : '23px')};

  > svg,
  > img {
    width: ${(p) => (p.isBigger ? '30px' : '24px')};
    height: ${(p) => (p.isBigger ? '30px' : '24px')};
  }
`;

const AppSva = styled.li`
  width: ${(p) => (p.isBigger ? '90px' : '23px')};
  height: ${(p) => (p.isBigger ? '30px' : '23px')};

  > svg,
  > img {
    width: ${(p) => (p.isBigger ? '90px' : '24px')};
    height: ${(p) => (p.isBigger ? '30px' : '24px')};
  }
`;

export default CardFooter;
