import styled from 'styled-components';

const Container = styled.div`
  margin: 0 auto;
  max-width: 1170px;
  padding: 0 15px;
  position: relative;
  width: 100%;

  p.migracao-meutim {
    color: #999999;
  }
`;

export { Container };
