import React from 'react';

import * as S from './styles';

function AppsCarros({ data }) {
  const { items } = data;
  const getIcon = (name) => `../images/card-carros/icon-${name}.svg`;

  return (
    <>
      <S.Title>
        Confira as <strong>assinaturas e apps inclusos no seu plano*</strong>
      </S.Title>
      <S.AppsContainer>
        {items.map((item, key) => (
          <S.App key={key}>
            <S.Icon className={item.sva.toLowerCase()} src={getIcon(item.icon)} />
            <div>
              <S.AppTitle>{item.sva}</S.AppTitle>
              <S.AppDescription>{item.description}</S.AppDescription>
              {item.subDescription.map((subitem, key) => (
                <S.AppSubDescription key={key}><strong>{subitem.title}:</strong> {subitem.content}</S.AppSubDescription>
              ))}
              {item.plan && <S.AppPlan><strong>{item.plan}</strong></S.AppPlan>}
            </div>
          </S.App>
        ))}
      </S.AppsContainer>
    </>
  );
}

export default AppsCarros;
