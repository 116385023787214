import React from 'react';
import styled from 'styled-components';

import { Container } from '../../styles/utils';
import { mobile, desktop } from '../../styles/media-queries';
import Item from './item';

const Svas = ({ sva }) => (
  <ContainerCentered>
    <Title>{sva.title}</Title>

    {sva.items && (
      <List>
        {sva.items.map((item, index) => (
          <Item item={item} key={index} />
        ))}
      </List>
    )}

    {sva.wrappers &&
      sva.wrappers.map((wrapper, index) => (
        <div key={index}>
          {wrapper.hint && <Hint>{wrapper.hint}</Hint>}
          <Subtitle>{wrapper.subtitle}</Subtitle>
          <List>
            {wrapper.items.map((item, wrapperIndex) => (
              <Item item={item} key={wrapperIndex} border={wrapper.border} />
            ))}
          </List>
        </div>
      ))}
  </ContainerCentered>
);

const ContainerCentered = styled(Container)`
  color: ${({ theme }) => theme.palette.text};
  text-align: center;

  ${mobile} {
    padding: 0 20px;
  }

  ${desktop} {
    padding: 0;
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.palette.secondary};
  font-weight: lighter;
  font-size: 32px;
  font-weight: normal;
  margin: 0 0 32px 0;
  text-transform: uppercase;
`;

const Subtitle = styled.h3`
  font-size: 24px;
  font-weight: normal;
  margin: 0 0 24px 0;
`;

const Hint = styled.p`
  font-size: 16px;
  margin: -30px 0 14px 0;
`;

const List = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

export default Svas;
