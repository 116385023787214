import React, { useState, useEffect } from 'react';
import * as S from './styles';
import { mountCarrosModalUrl } from '../../services/data/';
import { objectPropsIntoArray } from '../../services/utils/';

function CardCarros({ plan, uf }) {
  const [svaIncludes, setSvaIncludes] = useState([]);
  const [unlimitedApps, setUnlimitedApps] = useState([]);

  const getIcon = name => `../images/card-carros/icon-${name}.svg`;
  const { price, description } = plan;
  const sku = plan.skuCode.substring(0, 7);

  useEffect(() => {
    setSvaIncludes(objectPropsIntoArray(description.svasIncludes).filter(sva => sva.active));
    setUnlimitedApps(objectPropsIntoArray(description.unlimitedApps).filter(app => app.active));
  }, [plan]);

  return (
    <S.Container>
      {/* {description.internet.Titulo === '10GB' && (
        <S.BestOffer>
          <p>Melhor Oferta</p>
        </S.BestOffer>
      )} */}
      <S.Trial>Grátis por 30 Dias*</S.Trial>
      <S.Header>
        <S.PlanFranchise>
          <S.Plan>
            <h3>{description.internet.Titulo}</h3>
            <span>de internet</span>
          </S.Plan>
          <S.Price>
            <p>
              R$ <strong>{price.split(',')[0]}</strong> por mês
            </p>
            <span>no cartão de crédito</span>
          </S.Price>
        </S.PlanFranchise>
        <button
          data-action={`tim-carro-conectado-${description.internet.Titulo}-${parseInt(price)}-reais`}
          data-category="Tim - Carros Conectados"
          data-label="contratar-agora"
          type="button"
          onClick={() => mountCarrosModalUrl(sku, uf)}>
          Contratar agora
        </button>
      </S.Header>
      <S.Footer>
        <S.AppsContainer>
          {svaIncludes && svaIncludes.length > 0 && (
            <S.AppsContent isAlone={unlimitedApps.length === 0}>
              {svaIncludes.length > 1 ? (
                <S.AppsTitle>
                  Assinaturas <strong>inclusas</strong>
                </S.AppsTitle>
              ) : (
                <S.AppsTitle>
                  Assinatura <strong>inclusa</strong>
                </S.AppsTitle>
              )}

              <S.Apps>
                {svaIncludes.map((sva, key) => (
                  <S.App key={key}>
                    <img logo={sva.title.toLowerCase()} src={getIcon(sva.title.toLowerCase())} />
                  </S.App>
                ))}
              </S.Apps>
            </S.AppsContent>
          )}

          {unlimitedApps && unlimitedApps.length > 0 && (
            <S.AppsContent isAlone={svaIncludes.length === 0}>
              {unlimitedApps.length > 1 ? (
                <S.AppsTitle>
                  Apps <strong>inclusos</strong>
                </S.AppsTitle>
              ) : (
                <S.AppsTitle>
                  App <strong>incluso</strong>
                </S.AppsTitle>
              )}
              <S.Apps>
                {unlimitedApps.map((app, key) => (
                  <S.App key={key}>
                    <img logo={app.title.toLowerCase()} src={getIcon(app.title.toLowerCase())} />
                  </S.App>
                ))}
              </S.Apps>
            </S.AppsContent>
          )}

          {!svaIncludes && !unlimitedApps && <S.GapFill />}
        </S.AppsContainer>
        <S.Description>Compartilhamento da internet com até 8 pessoas simultaneamente.</S.Description>
      </S.Footer>
    </S.Container>
  );
}

export default CardCarros;
