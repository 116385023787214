import React from 'react';
import Rodal from 'rodal';
import styled from 'styled-components';
import 'rodal/lib/rodal.css';

import { mobile, desktop } from '../../styles/media-queries';
import bannerDesktop from './assets/banner-desktop.jpg';
import bannerMobile from './assets/banner-mobile.jpg';

const NetflixPopup = ({ visible, handleClose }) => (
  <Wrapper>
    <Rodal animation="slideDown" closeOnEsc={true} onClose={handleClose} visible={visible}>
      <Banner />
    </Rodal>
  </Wrapper>
);

const Wrapper = styled.div`
  background: red;

  .rodal-mask {
    background: rgba(0, 0, 0, 0.5);
  }

  .rodal-dialog {
    padding: 0;

    ${mobile} {
      height: 528px !important;
      width: 320px !important;
    }

    ${desktop} {
      height: 480px !important;
      width: 640px !important;
    }
  }

  .rodal-close {
    top: 7px;
    right: 7px;

    &:before,
    &:after {
      background: #fff;
    }

    &:hover {
      &:before,
      &:after {
        background: ${({ theme }) => theme.palette.secondary};
      }
    }
  }
`;

const Banner = styled.div`
  height: 100%;
  width: 100%;

  ${mobile} {
    background: url(${bannerMobile});
  }

  ${desktop} {
    background: url(${bannerDesktop});
  }
`;

export default NetflixPopup;
