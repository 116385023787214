import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1170px;
  margin: 32px auto;
`;

export const AccordionBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const AccordionItem = styled.section`
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.64);
  margin: 16px 0px;
  -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.64);
  padding: 24px 16px;
  border-radius: 16px;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.64);
`;

export const AccordionTitle = styled.h2`
  color: #4a4a4a;
  font-size: 18px;
`;

export const AccordionContent = styled.p`
  color: #4a4a4a;
  font-size: 16px;
  margin-top: 16px;
`;

export const AccordionIcon = styled.div`
  cursor: pointer;
  font-size: 24px;
  margin-right: 8px;
  z-index: 99999999999999999;
`;
