import React from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';

import { Container } from '../../styles/utils';
import { mobileWidth, mobile, desktop } from '../../styles/media-queries';
import { hasPlanLight } from '../../services/data';
import List from './list';
import Carousel from './carousel';
import { sortCardsByOrder } from '../../services/utils';

const PlansHall = ({ plans, messages, googleMessage, pageName, pageVersion }) => {
  const sortCards = sortCardsByOrder(plans);

  return (
    <ContainerStyled pageName={pageName}>
      {sortCards && (
        <>
          <MediaQuery maxDeviceWidth={mobileWidth}>
            <List plans={sortCards} pageName={pageName} googleMessage={googleMessage} pageVersion={pageVersion} />
          </MediaQuery>

          <MediaQuery minDeviceWidth={mobileWidth}>
            <List plans={sortCards} pageName={pageName} googleMessage={googleMessage} pageVersion={pageVersion} />
          </MediaQuery>

          <TextWrapper plans={sortCards}>{messages && messages.map((message, index) => <p key={index}>{message.text}</p>)}</TextWrapper>
        </>
      )}
    </ContainerStyled>
  );
};

const ContainerStyled = styled(Container)`
  ${mobile} {
    margin: -32px auto 40px auto;
    min-height: 360px;
  }

  ${desktop} {
    margin: ${props => (props.pageName === 'express' ? '-95px auto 40px auto' : ' -170px auto 40px auto')};
    min-height: 360px;
  }
`;

const TextWrapper = styled.div`
  color: #484848;
  display: flex;
  flex-wrap: wrap;
  font-weight: lighter;
  font-size: 12px;

  ${mobile} {
    flex-direction: column;
    margin: ${props => (props.plans.length === 1 ? '50px 0 0;' : '10px 0 0;')};
    text-align: center;
  }

  ${desktop} {
    justify-content: center;
    margin: 60px 0 0;
    text-align: center;

    > p:first-child {
      margin: 0 20px 0 0;
    }
  }
`;

export default PlansHall;
