import React from 'react';
import styled from 'styled-components';

import { desktop } from '../../styles/media-queries';
import Icon from '../icon';

const Item = ({ item, border }) => (
  <ItemStyled>
    {item.icon ? (
      <Icon id={`icon-${item.icon}`} extension={item.extension} sprite={item.sprite} />
    ) : (
      <img src={item.img} alt={item.description} />
    )}
    <Content border={border}>
      <Title>{item.sva}</Title>
      <Description>{item.description}</Description>
    </Content>
  </ItemStyled>
);

const ItemStyled = styled.li`
  margin: 0 15px 20px 15px;
  padding: 0 0 22px 0;
  position: relative;
  text-align: left;
  width: 356px;

  > svg,
  > img {
    height: 50px;
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
  }
`;

const Content = styled.div`
  padding: 0 0 0 80px;
  ${props => props.border &&
  `
    border-bottom: 1px solid #ddd;
    padding: 0  0 25px 80px;
  ` }
`;

const Title = styled.h4`
  font-size: 18px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary};
`;

const Description = styled.p`
  font-weight: lighter;
  margin: 4px 0 0;

  ${desktop} {
    height: 100px;
  }
`;

export default Item;
