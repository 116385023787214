import React from 'react';
import styled from 'styled-components';

import Icon from '../icon';
import { mobile, desktop } from '../../styles/media-queries';
import { Container } from '../../styles/utils';

const PlanDescription = () => (
  <Wrapper>
    <Title>Benefícios da Oferta Banco do Brasil</Title>
    <Cards>
      <CardItem>
        <CardHead>
          <CardTitle>O menor preço do mercado!</CardTitle>
        </CardHead>
        <CardBody>
          <CardText>20GB + até 4,5GB de bônus* por apenas R$85,99.</CardText>
        </CardBody>
      </CardItem>

      <CardItem>
        <CardHead>
          <CardTitle>Apps ilimitados</CardTitle>
        </CardHead>
        <CardBody>
          <CardIcon id="icon-whatsapp" />
          <CardIcon id="icon-instagram" />
          <CardIcon id="icon-facebook" />
          <CardIcon id="icon-twitter" />
        </CardBody>
      </CardItem>

      <CardItem>
        <CardHead>
          <CardTitle>Ligações ilimitadas</CardTitle>
        </CardHead>
        <CardBody>
          <CardText>Fale de graça com qualquer operadora do Brasil usando o DDD 41.</CardText>
        </CardBody>
      </CardItem>

      <CardItem>
        <CardHead>
          <CardTitle>Assinatura dos melhores serviços da TIM</CardTitle>
        </CardHead>
        <CardBody>
          <CardIcon id="icon-tim-music" />
          <CardIcon id="icon-tim-banca" />
        </CardBody>
      </CardItem>
    </Cards>
  </Wrapper>
);

const CardIcon = styled(Icon)`
  margin-left: 10px;
`;

const Wrapper = styled(Container)`
  max-width: 1060px;
`;
const Title = styled.h2`
  font-size: 24px;
  color: #003463;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 32px;
  font-weight: normal;
  text-transform: uppercase;
`;
const CardTitle = styled.h3`
  font-size: 24px;
  line-height: 26px;
  color: white;
  font-weight: lighter;
  text-align: center;
  width: 100%;
`;
const CardItem = styled.div`
  border-radius: 8px;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.3), 0 0 4px 0 rgba(0, 0, 0, 0.1);
  max-width: 224px;
  width: 100%;
  margin-left: 10px;
  &:first-child {
    margin-left: 0;
  }
  ${mobile} {
    margin: 15px 0;
  }
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${mobile} {
    flex-direction: column;
    align-items: center;
  }
`;
const CardHead = styled.div`
  background-image: linear-gradient(113deg, #4989c9, #235198 40%, #122d57 73%);
  height: 96px;
  padding: 0 20px;
  display: flex;
  justify-content: space;
  align-items: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;
const CardBody = styled.div`
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  img {
    width: 40px;
  }
  svg {
    width: 40px;
  }
`;
const CardText = styled.p`
  text-align: center;
  color: #003463;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
`;

export default PlanDescription;
