import React, {useState} from 'react';
import { useEffect } from 'react';
import * as S from './styles.js';

function LgpdBanner() {
  const [closeBanner, setCloseBanner] = useState(true);

  const cookiesPoliciesLink = 'https://cdn.celulardireto.com.br/TIM/estaticos/lgpd/politicas_cookies_tim_14_06_21_v1.pdf';
  let sessionStorageVerification = false;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorageVerification = window.sessionStorage.getItem('lgpd-dados') == 'true';
    }
  }, []);

  function handleCloseBanner() {
    if (closeBanner) {
      setCloseBanner(false);
      sessionStorage.setItem('lgpd-dados', 'true');
    }
  }

  return (
    <>
      { (closeBanner && !sessionStorageVerification) && (
        <S.LgpdInfo>
          <S.Wrapper>
            <p>
                Este site armazena cookies para coletar informações e melhorar sua experiência de
                navegação. Para saber mais sobre os cookies que usamos, consulte nossa &nbsp;
              <a
                href={cookiesPoliciesLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                política de cookies
              </a>.
            </p>
            <S.LgpdButton onClick={() => handleCloseBanner()}>Aceitar</S.LgpdButton>
          </S.Wrapper>
        </S.LgpdInfo>
      )}
    </>
  );
}

export default LgpdBanner;
