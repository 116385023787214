import styled from 'styled-components';
import {desktop, mobile} from '../../styles/media-queries';

export const Content = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  z-index: 9999999999999;
`;

export const ChatButton = styled.span`
  background-image: url('../images/ico-chat.svg');
  background-position: right;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  outline: none;

  ${mobile} {
    background-size: 60px 60px;
  }

  ${desktop}{
    background-size: 74px 74px;
  }
`;

export const Notification = styled.div`
  background-color:#CD0015;
  border-radius:50%;
  box-sizing:content-box !important;
  color:#fff;
  font-family:Arial,sans-serif !important;
  font-size:12px;
  font-weight:bold;
  padding: 3px;
  padding: 4px 7px;
  position:absolute;
  right:5px;
  top:5px;
  z-index: 9999999999999;

  ${mobile}{
    right: 15px;
    top: 10px;
  }

  ${desktop} {
    right: 15px;
    top: -10px;
  }
`;

export const Chat = styled.div`
    position: fixed;
    bottom: 100px;
    right: 20px;
    z-index: 999999999999999;
;
    overflow: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;


    iframe {
      border-radius: 10px;
      display: block;
      border: none;
      height: 600px;
      width:  400px;
    }


`;
