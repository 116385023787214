import React from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';

import { mobileWidth, mobile, desktop } from '../../styles/media-queries';
import { Container } from '../../styles/utils';
import Price from '../price';
import Button from '../button';

const BannerPromo = ({ mobileContent, desktopContent, plan }) => (
  <Wrapper>
    {mobileContent && (
      <BannerStyled
        mobileImage={mobileContent.banner.imageUrl}
        desktopImage={desktopContent.banner.imageUrl}
        bgColorDesktop={desktopContent.banner.colorFill}
        bgColorMobile={mobileContent.banner.colorFill}>
        <Container>
          <Logo>TIM</Logo>

          <RowData>
            <PlanFranchise>
              {plan.internetTotal.substr(0, 2)}
              <PlanFranchiseHelper>{plan.internetTotal.substr(2, 4)}</PlanFranchiseHelper>
            </PlanFranchise>
            <PlusBenefits>
              <PlusBenefitsParagraph>
                {plan.internet}
                <PlusBenefitsHelper>de internet</PlusBenefitsHelper>
              </PlusBenefitsParagraph>
              <PlusIcon>+</PlusIcon>
              <PlusBenefitsParagraph>
                {plan.internetBonus}
                <PlusBenefitsHelper>de vídeos</PlusBenefitsHelper>
              </PlusBenefitsParagraph>
            </PlusBenefits>
          </RowData>

          {plan.internetAdditional && (
            <RowAdditional>
              <PlanPlusTitle>
                {plan.internetAdditional.substr(0, 1)}
                <PlanPlusTitleHelper>{plan.internetAdditional.substr(1, 2)} de bônus</PlanPlusTitleHelper>
              </PlanPlusTitle>
            </RowAdditional>
          )}
          <PlanFeature>
            <MediaQuery maxDeviceWidth={mobileWidth}>
              {mobileContent.banner.features.map((item, index) => (
                <PlanFeatureItem key={index}>{item.feature}</PlanFeatureItem>
              ))}
            </MediaQuery>

            <MediaQuery minDeviceWidth={mobileWidth}>
              {desktopContent.banner.features.map((item, index) => (
                <PlanFeatureItem key={index}>{item.feature}</PlanFeatureItem>
              ))}
            </MediaQuery>
          </PlanFeature>

          <RowBuy>
            <PriceWrap>
              <OldPrice>
                {plan.hasDiscount && (
                  <>
                    De R$<span>{plan.price}</span> Por
                  </>
                )}
              </OldPrice>
              <BluePrice
                value={plan.priceDiscount.split(',')[0]}
                cents={plan.priceDiscount.split(',')[1]}
                hasDiscount={plan.hasDiscount}
              />
            </PriceWrap>
            <BuyButton plan={plan} openModal={() => MODAL.timPosUnificado({ sku: plan.sku })}>
              Contratar Agora
            </BuyButton>
          </RowBuy>
        </Container>
      </BannerStyled>
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  margin-bottom: 40px;

  ${mobile} {
    height: 607px;
  }

  ${desktop} {
    height: 450px;
  }
`;

const BannerStyled = styled.div`
  background-repeat: no-repeat;
  height: 100%;
  margin: 0 auto;
  width: 100%;

  ${mobile} {
    background-color: ${props => props.bgColorMobile};
    background-image: url('${props => props.mobileImage}');
    background-position: center center;
    background-size: contain;
    padding: 10px 0 0;
  }

  ${desktop} {
    background-color: ${props => props.bgColorDesktop};
    background-image: url('${props => props.desktopImage}');
    background-position: center;
    padding: 20px 0 0;
  }
`;

const BluePrice = styled(Price)`
  color: ${({ theme }) => theme.palette.lightBlue};
  width: 100%;
`;

const BuyButton = styled(Button)`
  margin: 0 0 0 26px;
  width: 232px;

  ${mobile} {
    margin-left: 0;
  }
`;

const Logo = styled.h1`
  background: url('/images/logo.svg') no-repeat;
  background-size: contain;
  text-indent: -9999px;

  ${mobile} {
    width: 100px;
  }

  ${desktop} {
    height: 97px;
    width: 236px;
  }
`;

const OldPrice = styled.p`
  color: ${({ theme }) => theme.palette.lightBlue};
  font-size: 14px;
  height: 17px;
  text-align: center;

  > span {
    text-decoration: line-through;
  }
`;

const PlusBenefits = styled.div`
  align-items: center;
  display: flex;
  margin: 5px 0 0 50px;

  ${mobile} {
    margin-left: 0;
  }
`;

const PlusBenefitsHelper = styled.span`
  display: block;
  font-size: 15px;

  line-height: 1;
  text-align: center;
`;

const PlusBenefitsParagraph = styled.p`
  color: white;

  font-size: 25px;
  line-height: 1;
  text-align: center;
`;

const PlanFranchise = styled.h3`
  color: white;
  font-size: 60px;

  font-weight: bold;
`;

const PlanFeature = styled.ul`
  color: white;
  display: flex;
  margin: 18px 0 0 0;
  width: 443px;
  flex-wrap: wrap;

  ${mobile} {
    flex-direction: column;
    margin: 0 auto;
    width: 232px;
  }
`;

const PlanFeatureItem = styled.li`
  align-items: center;
  color: white;
  display: flex;
  line-height: 1;
  margin: 0 0 10px 0;
  white-space: nowrap;
  width: 40%;
  ${mobile} {
    width: 100%;
  }

  &:before {
    align-self: flex-start;
    content: '+';
    color: #e91d2b;

    font-size: 26px;
    margin: -1px 10px 0 0;
  }
`;

const PlanFranchiseHelper = styled.small`
  font-size: 40px;
`;

const PlusIcon = styled.div`
  align-self: flex-start;
  color: #e91d2b;

  font-size: 40px;
  margin: -10px 6px 0;
`;

const PlanPlusTitle = styled.h4`
  color: white;
  font-size: 40px;
`;

const PlanPlusTitleHelper = styled.small`
  color: white;
  font-size: 24px;
`;

const PriceWrap = styled.div`
  display: flex;
  flex-direction: column;

  ${mobile} {
    margin-top: 10px;
    order: 1;
  }
`;

const RowAdditional = styled.div`
  align-items: center;
  display: flex;
  line-height: 1;
  margin: 5px 0px;

  ${mobile} {
    justify-content: center;
  }

  &:before {
    align-self: flex-start;
    content: '+';
    color: #e91d2b;

    font-size: 40px;
    margin-right: 13px;
  }
`;

const RowBuy = styled.div`
  align-items: flex-end;
  display: flex;
  margin: 20px 0 0;

  ${mobile} {
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
  }
`;

const RowData = styled.div`
  align-items: center;
  display: flex;
  margin-top: 20px;

  ${mobile} {
    flex-direction: column;
    margin-top: -5px;
  }
`;

export default BannerPromo;
