import styled from 'styled-components';
import {mobile, desktop} from '../../styles/media-queries';

import { ChatButton } from '../chat-button';

export const ContentBox = styled.div``;

export const Content = styled.section`
  bottom: 20px;
  padding: 10px 10px;
  position: fixed;
  right: 0;
  display: flex;
  gap: 10px;
  z-index: 1;
`;

export const Text = styled.span`
  background-color: white;
  border-radius: 50px;
  bottom: 10px;
  box-shadow: 3px 2px 7px -3px #000;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  padding: 6px 18px;

  ${mobile}{
    display: none;
    font-size: 14px;
  }

  ${desktop}{
    font-size: 17px;
  }
`;

export const ActionChat = styled(ChatButton)`
  display: inline-block;

  ${mobile}{
    height: 55px;
    margin: 15px 0;
    width: 164px;
  }

  ${desktop}{
    height: 76px;
    margin: -15px 0;
    width: 70px;
  }
`;
