import React, { useEffect, useState } from 'react';
import * as S from './styles';

const BannerFuncionamento = ({}) => (
  <S.Content>
    <S.CardBox>
      <S.Card>
        <h1>Mas o que é o TIM<br/> Carro Conectado?</h1>
        <p>São planos de Wi-fi que você pode contratar para o carro e manter até 8 pessoas navegando na internet ao mesmo tempo.
        Para que isso aconteça, os veículos compatíveis se conectam à rede por meio de um chip eletrônico, abarcado no carro,
        e o e-SIM que permite uma conexão da partida até o destino.</p>
      </S.Card>
      {/* <img src="/images/carroconectado/planet-renegade-desktop.svg" className="bannerImageDesktop" /> */}
      {/* <img src="/images/carroconectado/planet-renegade-desktop.svg" className="bannerImageMobile" /> */}
      
    </S.CardBox>
    <S.CardMobile>
      <h1>Como funciona?</h1>
      <p>Alguns modelos <strong>Jeep Renegade</strong> apresentam uma solução de
        tecnologia com e-Sim nos veículos,
        que possibilitam ser compatíveis com a conexão de um plano wi-fi para o carro.</p>
    </S.CardMobile>
    <S.Disclaimer>
      *Verifique a disponibilidade do app <strong>de acordo com o plano contratado.</strong>
    </S.Disclaimer>
  </S.Content>
);

export default BannerFuncionamento;
