import styled from 'styled-components';
import { mobile, desktop } from '../../styles/media-queries';

export const Container = styled.article`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: -2px 2px 8px 0 rgba(0, 0, 0, 0.3);
  flex: 1;
  margin: 20px;
  padding: 50px 16px 16px;
  position: relative;
`;

export const BestOffer = styled.div`
  background: url('../images/estrela.png') no-repeat;
  background-position: 10% 40%;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 300;
  left: 0;
  padding: 8px 16px 8px 45px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 45%;
`;

export const Trial = styled.div`
  background: #004691;
  border-radius: 8px;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  color: #ffffff;
  flex: 1;
  font-size: 16px;
  font-weight: 900;
  padding: 8px 16px;
  position: absolute;
  right: 0;
  top: 0;
  white-space: nowrap;
`;

export const Header = styled.header`
  button {
    background: #eb0028;
    border-radius: 20px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-size: 16px;
    margin: 19px auto 24px;
    outline: none;
    padding: 9px 24px;
    white-space: nowrap;
    width: 180px;
  }
`;

export const PlanFranchise = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
  ${mobile} {
    flex-direction: row;
  }
  @media screen and (max-width: 320px) {
    flex-direction: column;
  }
  h3,
  strong {
    color: #004691;
    display: inline-block;
    font-size: 40px;
    margin: 0 0 2px;
    ${mobile} {
      font-size: 30px;
    }
  }
  p {
    color: #4a4a4a;
    font-size: 14px;
  }
  span {
    color: #4a4a4a;
    display: block;
    font-size: 12px;
    font-weight: 300;
  }
`;

export const Plan = styled.div`
  margin: 0 auto;
  text-align: center;
`;

export const Price = styled.div`
  margin: 0 auto;
  text-align: center;
`;

export const Footer = styled.footer``;

export const AppsContainer = styled.div`
  display: flex;
  width: 100%;

  p {
    font-weight: 300;
    strong {
      font-weight: 900;
    }
  }
`;

export const AppsContent = styled.div`
  position: relative;
  margin: ${props => (props.isAlone ? '0' : '0 auto')};
`;

export const AppsTitle = styled.p`
  color: #004691;
  font-size: 16px;
  ${mobile} {
    font-size: 14px;
  }
`;

export const Apps = styled.ul`
  display: flex;
`;
export const App = styled.li`
  position: relative;

  img {
    height: 44px;
    margin: 9px 4px 0;
    width: 44px;
  }

  [logo='waze'] {
     margin: 9px 4px 0;
    height: auto;
    width: auto;
  }

  [logo='audiobook'] {
    margin: 9px 4px 0;
  }

`;

export const Description = styled.p`
  color: #004691;
  font-size: 14px;
  font-weight: 300;
  margin: 24px 0 0;
`;

export const GapFill = styled.div`
  display: block;
  min-height: 55px;
  width: 100%;
`;
