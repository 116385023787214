import React from 'react';
import styled from 'styled-components';

const Price = ({ value, cents, className, hasDiscount }) => {
  return (
    <Wrapper className={className}>
      <Currency>R$</Currency>
      <Value>{value}</Value>
      <Cents>{cents}</Cents>
      <Month>
        /mês
        {hasDiscount && <span>*</span>}
      </Month>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-weight: bold;
  line-height: 1;
  position: relative;
  text-align: center;
`;

const Currency = styled.p`
  display: inline;
  font-size: 20px;
`;

const Value = styled.strong`
  font-size: 50px;
`;

const Cents = styled.strong`
  font-size: 20px;
  margin: 0 0 0 4px;
  position: absolute;
  top: 0;
`;

const Month = styled.p`
  display: inline;
  font-size: 20px;
  margin: 0 0 0 -4px;
  text-transform: uppercase;
`;

export default Price;
