import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

import { mobile, desktop } from '../../styles/media-queries';

const Badge = ({ text }) => (
  <Wrapper>
    <p>{text}</p>
  </Wrapper>
);

const Wrapper = styled.div`
  background: ${({ theme }) => theme.palette.secondary};
  border-radius: 0 0 10px 10px;
  color: #fff;
  font-weight: bold;
  left: 0;
  margin: 0 auto;
  padding: 0px;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: -10px;
  width: 143px;

  &:before,
  &:after {
    content: '';
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid ${props => darken(0.2, props.theme.palette.secondary)};
    height: 0;
    position: absolute;
    top: 0;
    width: 0;
    z-index: -1;
  }

  &:before {
    left: -13px;
  }

  &:after {
    right: -13px;
  }

  ${mobile} {
    font-size: 12px;
  }

  ${desktop} {
    font-size: 13px;
  }
`;

export default Badge;
