import styled from 'styled-components';
import { mobile } from '../../styles/media-queries';

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  width: 100%;

  ${mobile} {
    height: 100%;
  }

  &.-default {
    background-color: #000;
  }

  &.-carro-conectado {
    background-color: #003263;
  }
`;

export const Text = styled.p`
  color: #fff;
  margin: 8px 0;
  padding: 0px 32px;
  text-align: center;
`;
