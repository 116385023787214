import React from 'react';
import styled from 'styled-components';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Card from '../card';
import Icon from '../icon';
import { hasPlanWithRestrictedBenefit, hasPlanLight } from '../../services/data';

const getIsHiglightedIndex = plans => {
  const index = plans.findIndex(plan => plan.isHighlihted);
  return index > -1 ? index : 0;
};

const Carousel = ({ plans, pageName }) => (
  <CarouselWrapper
    naturalSlideWidth={100}
    naturalSlideHeight={1}
    totalSlides={plans.length}
    infinite="false"
    currentSlide={getIsHiglightedIndex(plans)}>
    <SliderStyled hasPlanWithRestrictedBenefit={hasPlanWithRestrictedBenefit(plans)} hasPlanLight={hasPlanLight(plans)}>
      {plans.map((plan, index) => (
        <Slide key={index}>
          <Card plan={plan} pageName={pageName} />
        </Slide>
      ))}
    </SliderStyled>

    <ButtonsWrapper>
      <Back>
        <Icon id="icon-arrow" />
      </Back>
      <Next>
        <Icon id="icon-arrow" />
      </Next>
    </ButtonsWrapper>

    <Dots />
  </CarouselWrapper>
);

const getSliderHeight = (hasPlanWithRestrictedBenefit, hasPlanLight) => {
  if (hasPlanWithRestrictedBenefit) {
    return '385px';
  } else if (hasPlanLight) {
    return '377px';
  }
  return '554px';
};

const CarouselWrapper = styled(CarouselProvider)`
  position: relative;
`;

const SliderStyled = styled(Slider)`
  height: ${({ hasPlanWithRestrictedBenefit, hasPlanLight }) => getSliderHeight(hasPlanWithRestrictedBenefit, hasPlanLight)};
  padding: 10px 0 0;
`;

const Back = styled(ButtonBack)`
  left: 0;
  transform: rotate(180deg);
`;

const Next = styled(ButtonNext)`
  right: 0;
`;

const ButtonsWrapper = styled.div`
  svg {
    width: 29px;
    height: 47px;
  }

  > ${Back}, > ${Next} {
    background: none;
    border: none;
    position: absolute;
    top: 20%;
    transition: opacity 0.2s ease;

    &:disabled {
      opacity: 0.5;
    }
  }
`;

const Dots = styled(DotGroup)`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px 0 0;

  > button {
    background: #fff;
    border: 2px solid ${({ theme }) => theme.palette.primary};
    border-radius: 50%;
    height: 16px;
    transition: background 0.4s linear;
    width: 16px;

    &:disabled {
      background: ${({ theme }) => theme.palette.primary};
    }

    &:not(:last-child) {
      margin: 0 16px 0 0;
    }
  }
`;

export default Carousel;
