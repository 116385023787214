import React from 'react';
import * as S from './styles';

export default function FooterFixed({ template = '-default' }) {
  return (
    <S.Content className={template}>
      <S.Text>
        TIM S/A | CNPJ : 02.421.421/0001-11 | Insc.Municipal : 0261388-3 |
        Insc.Estadual : 86.092.08-5 | Endereço : Rua Fonseca Teles, 19,
        São Cristóvão, Rio de Janeiro – RJ, CEP: 20940-200
      </S.Text>
      <S.Text>© 2021 Allied. Todos os direitos reservados. Segurança e privacidade.</S.Text>
    </S.Content>
  );
};

