import React from 'react';
import BgDesktop from '../../../images/banner-carros/banner-desktop.png';
import BgMobile from '../../../images/banner-carros/banner-mobile.png';
import * as S from './styles';

export default function BannerCarros() {
  return (
    <S.Container>
      <S.Banner desktopImage={BgDesktop} mobileImage={BgMobile} />
    </S.Container>
  );
}
