import React from 'react';
import styled from 'styled-components';

import netflixMobile from './assets/banner-netflix-mobile.png';
import netflixDesktop from './assets/banner-netflix-desktop.png';
import { mobile, desktop } from '../../styles/media-queries';

const bannerToShow = netflixMobile;

const NetflixBanner = () => <Banner bannerToShow={bannerToShow} />;

const Banner = styled.button`
  background: none;
  background-repeat: no-repeat;
  padding: 0 15px;
  border: none;
  display: block;
  margin: 0 auto 40px auto;

  ${mobile} {
    background-image: url(${({ bannerToShow }) => (bannerToShow)});
    background-size: contain;
    height: 480px;
    max-width: 320px;
    width: calc(100% - 25px);
  }

  ${desktop} {
    background-image: url(${() => (netflixDesktop)});
    background-size: contain;
    height: 200px;
    max-width: 1140px;
    width: calc(100% - 25px);
  }
`;

export default NetflixBanner;
