import React, { useState } from 'react';
import styled from 'styled-components';


import CardHeader from './card-header';
import CardFooter from './card-footer';
import Badge from './badge';

const Card = ({ plan, pageName, googleMessage }) => {

  return (
    <Wrapper hasRestrictedBenefit={plan.hasRestrictedBenefit} pageName={pageName}>
      {plan.badgeMessage && <Badge text={plan.badgeMessage} />}

      <CardHeader plan={plan} googleMessage={googleMessage} pageName={pageName} />

      <CardFooter plan={plan} pageName={pageName} />
    </Wrapper>
  );
};



const getWrapperHeight = (hasRestrictedBenefit, pageName) => {
  if (hasRestrictedBenefit) {
    return '332px';
  } else if (pageName === 'light') {
    return '367px';
  } else if (pageName === 'express') {
    return '367px';
  }
  return '513px';
};

const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  min-height: ${({ hasRestrictedBenefit, pageName }) => getWrapperHeight(hasRestrictedBenefit, pageName)};
  height: 100%;
  margin: 0 10px;
  position: relative;
  width: 275px;
  margin: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
`;

export default Card;
