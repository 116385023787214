import React from 'react';
import * as S from './styles';
import Desktop from '../../../images/parceiros/banner-c6-desktop.png';
import Mobile from '../../../images/parceiros/banner-c6-mobile.png';

function BannerC6() {
  return(
    <S.Container>
      <S.BannerDesktop src={Desktop} />
      <S.BannerMobile src={Mobile} />
    </S.Container>
  );
}

export default BannerC6;
