import React from 'react';
import styled from 'styled-components';

import { mobile } from '../../styles/media-queries';

const UFButton = ({ uf, onUFRequest, noBanner }) => (
  <Button noBanner={noBanner} onClick={onUFRequest}>
    {uf}
  </Button>
);

const Button = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  height: 100%;
  line-height: 0.9;
  outline: none;

  ${mobile} {
    position: absolute;
    top: ${props => (props.noBanner ? '0' : '48px')};
    right: 20px;
    z-index: 999;
  }

  &:before {
    background: url('/images/location.svg') no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 28px;
    margin: 0 6px 0 0;
    width: 20px;
  }

  &:after {
    border: solid #fff;
    border-width: 0 3px 3px 0;
    content: '';
    display: block;
    margin: -2px 0 0 6px;
    padding: 3px;
    transform: rotate(45deg);
  }
`;

export default UFButton;
