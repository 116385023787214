import React from 'react';

import styled from 'styled-components';

const Loader = ({ isLoading }) => (
  <>
    {isLoading && (
      <Overlay>
        <Spinner src="/images/loader.svg" alt="Loader" />
      </Overlay>
    )}
  </>
);

const Overlay = styled.div`
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
`;

const Spinner = styled.img`
  max-width: 100px;
`;

export default Loader;
