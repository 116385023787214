import React from 'react';
import styled from 'styled-components';

import { mobile, desktop } from '../../styles/media-queries';
import { Container } from '../../styles/utils';

const BannerCta = ({ pageName }) => (
  <BannerBg>
    <Wrapper>
      <Tag45g src="/images/icons/tag45g.svg"></Tag45g>
      <CtaImage
        onClick={() => MODAL.timPosUnificado({ sku: 'TPTF946', flow: 'bancodobrasil' })}
        data-analytics-selector="btn-contratar-pos-bb"
      />
    </Wrapper>
  </BannerBg>
);

const BannerBg = styled.div`
  height: 300px;
  width: 100%;
  background: linear-gradient(-70deg, rgba(18, 45, 87, 1) 40%, rgba(73, 137, 201, 1) 80%);
  ${mobile} {
    height: 489px;
    background: linear-gradient(-35deg, rgba(18, 45, 87, 1) 36%, rgba(73, 137, 201, 1) 100%);
  }
`;
const CtaImage = styled.a`
  background: url('/images/parceiros/plano-bb-desk.png') no-repeat center;
  background-size: contain;
  height: 144px;
  width: 100%;
  max-width: 1016px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  ${mobile} {
    background: url('/images/parceiros/plano-bb-mobile.png') no-repeat center;
    height: 388px;
    max-width: 275px;
  }
`;

const Tag45g = styled.img`
  width: 108px;
  margin-left: auto;
  margin-top: 26px;
  margin-bottom: 10px;
  ${mobile} {
    width: 51px;
    margin-top: 48px;
    margin-right: 5px;
  }
`;

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
`;

export default BannerCta;
