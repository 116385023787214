import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { lighten } from 'polished';

const Button = ({ className, children, planSku, planInternet, openModal, dataAnalyticsSelector }) => (
  <ButtonStyled
    data-analytics-selector={`${dataAnalyticsSelector || 'btn-contratar-pos'}`}
    data-analytics-franquia={planInternet}
    onClick={() => openModal(planSku)}
    className={`action ${className}`}>
    {children}
  </ButtonStyled>
);

const ButtonStyled = styled.button`
  background: ${({ theme }) => theme.palette.button};
  border: 0;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  height: 45px;
  margin: 0 8px 8px 8px;
  outline: 0;
  padding: 0;
  text-transform: uppercase;
  transition: background 0.3s linear;
  width: 100%;

  &:hover {
    background: ${({ theme }) => lighten(0.1, theme.palette.button)};
  }

  &.card{
    border-radius: 22px;
    margin: 0 auto;
  }
`;

Button.propTypes = {
  openModal: PropTypes.func.isRequired,
  dataAnalyticsSelector: PropTypes.string,
};

export default Button;
