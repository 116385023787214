import styled from 'styled-components';
import {mobile} from '../../styles/media-queries';

export const LgpdInfo = styled.div`
  align-items: center;
  background-color: #0026D9;
  bottom: 0;
  display: flex;
  height: 88px;
  justify-content: center;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 99999999999999999;

  ${mobile} {
    height: 155px;
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  p {
    color: #FFF;
    font-size: 14px;
    margin: 0px 12px;
  }

  a {
    color: #FFF;
    text-decoration: underline;
  }
`;

export const LgpdButton = styled.button`
  background-color: #FFF;
  border: 0;
  border-radius: 12px;
  cursor: pointer;
  font-size: 14px;
  margin: 0px 12px;
  padding: 18px 24px;
`;
