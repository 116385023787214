import styled from 'styled-components';
import { mobile } from '../../styles/media-queries';

export const Title = styled.h2`
  color: #4a4a4a;
  font-size: 32px;
  font-weight: 300;
  text-align: center;
  strong {
    color: #004691;
  }
`;

export const AppsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 50px;
  ${mobile} {
    flex-direction: column;
  }
  @media screen and (max-width: 500px) {
    padding: 0 20px;
    img {
      height: 34px;
      width: 34px;
    }
  }
`;

export const App = styled.div`
  align-items: flex-start;
  color: #4a4a4a;
  display: flex;
  margin: 80px 0 0 40px;
  width: 40%;
  @media screen and (max-width: 950px) {
    align-items: flex-start;
  }
  ${mobile} {
    margin: 80px 0 0 0;
    width: 100%;
  }
  div {
    align-self: flex-start;
    margin-left: 20px;
    width: 100%;
  }

  [logo='waze'] {
    width: auto;
    height: auto;
  }


`;

export const AppTitle = styled.h4`
  font-size: 18px;
`;

export const AppDescription = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin-top: 12px;
  padding-right: 55px;
  @media screen and (max-width: 950px) {
    padding-right: 0;
  }
`;

export const AppSubDescription = styled.p`
  font-size: 11px;
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 12px;
  padding-right: 55px;
  color: #696969;
  @media screen and (max-width: 950px) {
    padding-right: 0;
  }`
;

export const AppPlan = styled.p`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  margin-top: 12px;
  padding-right: 55px;
  color: #004691;
  @media screen and (max-width: 950px) {
    padding-right: 0;
  }
`;


export const Icon = styled.img`
  height: 64px;
  width: 64px;
  margin-top: 20px;

  &.waze {
    width: 67px;
  }


`;
