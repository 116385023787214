import React from 'react';
import styled from 'styled-components';

import { mobile } from '../../styles/media-queries';
import Card from '../card';

const List = ({ plans, pageName, googleMessage, pageVersion }) => {
  return (
    <Wrapper plans={plans}>
      {plans.map((plan, index) => (
        <Item key={index} isHighlighted={plan.isHighlihted} pageName={pageName}>
          <Card plan={plan} pageName={pageName} pageVersion={pageVersion} />
        </Item>
      ))}
    </Wrapper>
  );
};

const Item = styled.li`
  transition: transform 0.2s ease-in;
  transform: ${({ isHighlighted, pageName }) => (isHighlighted && pageName !== 'light' ? 'scale(1.1)' : 'scale(1)')};
  z-index: ${({ isHighlighted, pageName }) => (isHighlighted && pageName !== 'light' ? '2' : '1')};

  &:hover {
    transform: scale(1.1)!important;
    z-index: 2 !important;
  }
  ${mobile} {
    margin: 0 auto;
    flex: 1;
    transform: unset;
    &:hover {
      transform: unset!important;
    }
  }
`;

const Wrapper = styled.ul`
  display: flex;
  justify-content: center;

  &:hover ${Item} {
    transform: scale(1);
    z-index: 1;
  }
  ${mobile} {
    ${props => props.plans.length === 1 && 'margin-top: 45px;'}
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: scroll;
    padding: 0 0 20px;
  }
`;

export default List;
