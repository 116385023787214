import React from 'react';
import * as S from './styles';
import map from '../../../images/map.png';

export default function CoverageMap() {
  return (
    <S.Content>
      <S.Icon src={map}/>
      <S.Text>
        Acesse o Mapa de Cobertura em nosso site para verificar a abrangência do serviço na sua região
      </S.Text>
    </S.Content>
  );
};
