import * as UtilsService from '../utils';

const API_BUILD = (data, content) => {
  const plans = data.map(plan => {
    const description = descriptionToObject(plan);
    const card = content.cards ? content.cards.find(card => card.sku === plan.CodigoSku.substr(0, 7)) || {} : {};

    return {
      internet: description.internet,
      internetAdditional: description.internetAdditional,
      internetBonus: description.internetBonus,
      internetDebitBonus: description.internetDebitBonus,
      acumulateInternet: description.acumulateInternet,
      appletv: description.appletv,
      passaportAmericas: description.passaportAmericas,
      unlimitedApps: description.unlimitedApps,
      svasIncludes: description.svasIncludes,
      bonusC6Bank: description.bonusC6Bank,
      internetTotal: UtilsService.sumInternet([
        description.internet,
        description.internetAdditional,
        description.bonusC6Bank,
        description.internetPortabilityBonus,
      ]),
      internetPortabilityBonus: description.internetPortabilityBonus,
      paymentMethod: 'fatura',
      price: plan.Valor,
      priceDiscount: plan.ValorComDesconto,
      isHighlihted: plan.CodigoSku.substr(0, 7) === content.highlightedPlan,
      sku: plan.CodigoSku.substr(0, 9),
      underContract: plan.Fidelizado || false,
      digitalInvoiceBonus: description.internetApps,
      hasDiscount: hasDiscount(plan),
      appList: getAppList(plan, description),
      subscriptions: getSubscriptions(plan, description),
      badge: content.highlightedPlanBadge,
      isSocialApps: description.socialApps,
      hasRestrictedBenefit: description.hasRestrictedBenefit,
      noVideoPartners: description.noVideoPartners,
      isLight: description.isLight,
      badgeMessage: card.badge || false,
      cssClass: card.cssClass || '',
      portabilityBonus: card.portabilityBonus || null,
      hasDebit: card.hasDebit === 'true',
      hasDigitalInvoice: card.hasDigitalInvoice === 'true',
      order: parseInt(card.order),
      planC: content.planC,
      vas: card.vasCards,
    };
  });
  return plans;
};

const descriptionToObject = plan => {
  const description = JSON.parse(plan.Descricao);
  const object = {
    internet: description.internet.Titulo.replace('7+', ''),
    internetBonus: description.internet.Titulo.replace('7+', ''),
    internetDebitBonus: description.debitoAutomaticoBonus ? description.debitoAutomaticoBonus.Titulo : '',
    internetAdditional: getDescriptionTitle(description.internetBonusDiaConsumidor),
    internetPortabilityBonus: getDescriptionTitle(description.bonusPortabilidade),
    socialApps: description.posSocial ? true : false,
    bonusC6Bank: getDescriptionTitle(description.bonusC6Bank),
    internetApps: getDescriptionTitle(description.internetBonus),
    hasRestrictedBenefit: !!description.restricaoBeneficios,
    isLight: !!description.posLight,
    noVideoPartners: description.semBonusVideo || false,
    acumulateInternet: description.acumulateInternet || false,
    appletv: description.appletv || false,
    passaportAmericas: description.passaportAmericas || false,
    unlimitedApps: description.unlimitedApps || false,
    svasIncludes: description.svasIncludes || false,
  };
  return object;
};

const getDescriptionTitle = description => {
  if (description) {
    return (description.Titulo ? description.Titulo : description.titulo) || '';
  }
  return '';
};

const hasDiscount = plan => {
  const oldPrice = Number(plan.Valor.replace(',', '.'));
  const newPrice = Number(plan.ValorComDesconto.replace(',', '.'));
  return oldPrice > newPrice;
};

const getAppList = (plan, description) => {
  const internet = plan.Complemento.dados_valor.replace('7+', '');
  const social = ['whatsapp', 'messenger', 'waze', 'telegram'];
  const video = ['netflix', 'tim-looke', 'cartoon', 'ei'];
  if (description.noVideoPartners) {
    return {
      social: ['whatsapp', 'instagram', 'facebook', 'twitter'],
    };
  } else if (description.isLight) {
    return {
      social: ['facebook', 'whatsapp', 'instagram', 'twitter'],
      video,
    };
  } else if (internet === '9GB' || internet === '7GB') {
    return {
      social: ['whatsapp', 'messenger', 'waze', 'telegram'],
      video,
    };
  } else if (internet !== '6GB') {
    return {
      social: ['facebook', 'instagram', 'twitter', ...social],
      video: ['youtube', ...video],
    };
  }
  return {
    social,
    video,
  };
};

const getSubscriptions = (plan, description) => {
  const internet = plan.Complemento.dados_valor.replace('7+', '');
  const VasListOne = ['Deezer Premium', 'BANCAH PREMIUM + JORNAIS', 'Aya Audiobooks', 'TIM Segurança Digital'];
  const VasListTwo = [
    'Deezer Premium',
    'BANCAH PREMIUM + JORNAIS',
    'TIM Protect Backup 30GB',
    'Cartoon Network',
    'Esporte Interativo Plus',
    'TIM Vídeos By Looke',
  ];
  const VasListThree = ['Deezer Premium', 'BANCAH PREMIUM + JORNAIS', 'Aya Audiobooks', 'TIM Segurança Digital'];
  const VasListFour = ['Deezer Premium', 'BANCAH PREMIUM + JORNAIS', 'TIM Protect Backup 5GB'];
  const VasListFive = ['Deezer Premium', 'BANCAH PREMIUM + JORNAIS', 'Aya Audiobooks', 'TIM Segurança Digital Light'];
  if (internet === '9GB') {
    return VasListThree;
  } else if (description.noVideoPartners || internet === '15GB') {
    return VasListFive;
  } else if (internet === '50GB') {
    return VasListTwo;
  } else if (internet === '7GB' || internet === '6GB') {
    return VasListFour;
  }
  return VasListOne;
};

export default API_BUILD;
