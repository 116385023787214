import styled from 'styled-components';
import { mobile, desktop, mobileSmall} from '../../styles/media-queries';




export const Content = styled.div`
  width: 100%;
  background-color: #002280;
  margin-top: 100px;
  padding: 70px 0;


  ${mobile} {
     background-color: #002280;
    background-position: center top;
  }

  ${mobileSmall} {
    background-size: contain;
  }

  > img {
    width: 70%;

    
    ${mobile} {
      width: 100%;
    }

    ${mobileSmall} {
      width: 100%;
    }
  }

  .bannerImageDesktop {
    ${mobile} {
      display: none
    }

    ${desktop} {
      display: block;
    }
  }

  .bannerImageMobile {
    ${mobileSmall} {
      width: 300px;
      height: 250px
    }

    ${mobile} {
       display: block
    }
    ${desktop} {
     display: none;
    }
  }
`;

export const CardBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
`;

export const Card = styled.div`
  height: 218px;
  padding: 19px 18px 27px 27px;
  max-width: 752px;

  ${mobile} {
    display: none;
  }


  > h1 {
    font-size: 2rem;
    color: #fff;
    margin-bottom: 30px;
    font-weight: 300;
  }

  > p {
    color: #fff;
    font-size: 1rem;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
  }

  > strong {
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
`;

export const CardMobile = styled.div`
  ${desktop} {
    display: none;
  }

  height: auto;
  width: 100%;
  margin: auto;
  padding: 19px 18px 27px 27px;
  margin-bottom: 30px;
  margin-top: 30px;

  > h1 {
    font-size: 2rem;
    color: #fff;
    font-weight: 300;
    margin-bottom: 15px;
    text-align: center;
  }

  > p {
    font-size: 1rem;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
    text-align: center;
  }

  > strong {
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
`;

export const Disclaimer = styled.p`
  color: #fff;
  display: block;
  font-size: 14px;
  margin: 35px auto 60px;
  padding: 0 45px;
  text-align: center;

  > strong {
    text-decoration: underline;
  }
  
   ${mobile} {
    margin-top: 10px;
  }
`;
