import axios from 'axios';

import env from '../../../../environment';
import API_BUILD from './builder';

const envConfig = {
  url: env.API_URL,
  carrosApiUrl: env.API_CARROS_URL,
  carrosModalUrl: env.CARROS_MODAL_URL,
  urlDefault: env.DEFAULT_UF,
  originCode: env.ORIGIN_CODE,
  originCodeExpress: env.ORIGIN_CODE_EXPRESS,
  originCodeVendedor: env.ORIGIN_CODE_VENDEDOR,
  originCodeGoogle: env.ORIGIN_CODE_GOOGLE_MESSAGE,
  headers: {
    'Authorization': env.AUTHORIZATION,
    'CodigoOperadora': env.COMPANY_CODE,
  },
};

export const getData = async (page = 'home', uf = env.DEFAULT_UF, campaign = 'default', googleMessage) => {
  try {
    const url = mountApiURL(uf, page, campaign, googleMessage);
    const data = (await axios.get(url, envConfig)).data;
    const content = data.Conteudo;
    if (page === 'familia' || page === 'familia-vendedor') {
      return content;
    }
    const displayPlans = content.cards ? content.cards.map((card) => card.sku) : null;
    const build = API_BUILD(data.Skus, content);
    const plans = page === '7gb' ? build : orderPlans(filterPlans(build, displayPlans));
    return data.Skus.length > 0 ? { plans, content } : getPlans();
  } catch (error) {
    throw error;
  }
};

export const hasPlanWithRestrictedBenefit = (plans) => {
  return plans.every((plan) => plan.hasRestrictedBenefit);
};

export const getCarrosConectadosData = async (uf) => {
  const params = {
    'originCode': env.ORIGIN_CODE,
    'operatorCode': env.COMPANY_CODE,
    'uf': uf || env.DEFAULT_UF,
    'sku': '',
  };

  try {
    const data = await axios.post(envConfig.carrosApiUrl, params);
    const content = JSON.parse(data.request.response);
    const plans = content.retorno.products;
    return plans;
  } catch (err) {
    throw err;
  }
};

export const mountCarrosModalUrl = (skuCode, uf) => {
  if (typeof window !== 'undefined') {
    let urlParams = _getQueryParams();

    urlParams = _replaceParamsName('utm_', 'trk_', urlParams);
    urlParams.origin = window.location.origin;
    urlParams.pathname = window.location.pathname;
    urlParams.sku = skuCode;
    urlParams.uf = uf;

    const url = `${envConfig.carrosModalUrl}?${_generateUrl(urlParams)}`;
    window.open(url);
  }
};

export const hasPlanLight = (plans) => plans.every((plan) => plan.isLight);

const mountApiURL = (uf, page, campaign, googleMessage) => {
  const url =
    envConfig.url +
    '?UFDefault=SP' +
    `&CodigoCampanha=${campaign}` +
    `&OrigemCampanha=${getCampaignOrigin(page)}` +
    `&EstadoCampanha=${uf}` +
    `&CodigoOrigem=${getOrigin(page, googleMessage)}` +
    `&Uf=${uf}`;
  return url;
};

const getCampaignOrigin = (page) => {
  switch (page) {
    case 'light':
      return 'pos-light';
    case 'light-vendedor':
      return 'pos-light';
    case 'familia':
      return 'pos-familia';
    case 'express':
      return 'pos-express';
  }
  return 'planos-pos';
};

const getOrigin = (page, googleMessage) => {
  if (page === 'express') {
    return envConfig.originCodeExpress;
  } else if (page.includes('vendedor')) {
    return envConfig.originCodeVendedor;
  } else if (page.includes('home') && googleMessage) {
    return envConfig.originCodeGoogle;
  } else {
    return envConfig.originCode;
  }
};

const filterPlans = (plans, displayPlans) => {
  return plans.filter((plan) => {
    return displayPlans.includes(plan.sku.substr(0, 7));
  });
};

const orderPlans = (plans) => plans.sort((a, b) => (a.order > b.order ? 1 : -1));

const _generateUrl = (data) => {
  const ret = [];
  for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
};

const _getQueryParams = () => {
  const locationSearch = typeof window !== 'undefined' ? window.location.search.replace(/^\?/, '') : '';
  const searchs = locationSearch.split('&');
  const returnedQuerys = {};

  searchs.forEach((query) => {
    const parse = query.split('=');
    const prop = parse[0];
    const val = parse[1];

    if (Boolean(val)) {
      returnedQuerys[prop] = val;
    }
  });

  return returnedQuerys;
};

const _replaceParamsName = (from, to, list) => {
  let prop, hasParamToBeReplaced;

  for (prop in list) {
    hasParamToBeReplaced = prop.indexOf(from) > -1;

    if (hasParamToBeReplaced) {
      const replacedParamName = prop.replace(from, to);
      list[replacedParamName] = list[prop];
      delete list[prop];
    }
  }

  return list;
};
