import React from 'react';
import PropTypes from 'prop-types';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import styled from 'styled-components';

import logo from './assets/logo.svg';
import arrowDown from './assets/arrow-down.svg';

const UFSelector = ({ ufs, selectedUF, handleCloseUFSelector, handleChangeSelect, visible = true }) => (
  <Rodal
    animation="slideDown"
    closeOnEsc={true}
    onClose={handleCloseUFSelector}
    visible={visible}
    customStyles={{
      borderRadius: '10px',
      height: '230px',
      width: '320px',
    }}>
    {selectedUF && (
      <Modal>
        <Image src={logo} />

        <Label htmlFor="select-uf">Selecione o seu estado</Label>

        <Body>
          <Select onChange={handleChangeSelect} id="uf" name="select" defaultValue={selectedUF}>
            {ufs.map(uf => (
              <option value={uf.abbreviation} key={uf.abbreviation}>
                {uf.name}
              </option>
            ))}
          </Select>
        </Body>

        <Button onClick={handleCloseUFSelector} type="submit">
          Selecionar
        </Button>
      </Modal>
    )}
  </Rodal>
);

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;

const Image = styled.img`
  display: block;
  height: 30px;
  margin: 24px auto 11px auto;
  width: 110px;
`;

const Label = styled.label`
  color: #23507a;
  display: block;
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 8px 0;
  text-align: center;
`;

const Body = styled.div`
  position: relative;

  &:after {
    background: url(${arrowDown}) no-repeat;
    content: '';
    display: block;
    height: 18px;
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 6px;
    width: 18px;
  }
`;

const Select = styled.select`
  border: none;
  border-bottom: 1px solid #c1c1c1;
  cursor: pointer;
  font-size: 16px;
  height: 24px;
  margin: 0 0 20px;
  padding: 0 0 4px;
  text-transform: uppercase;
  outline: none;
  -webkit-appearance: none;
  width: 100%;

  &::-ms-expand {
    display: none;
  }
`;

const Button = styled.button`
  background: #58a20b;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  outline: none;
  min-width: 272px;
  padding: 13px 0;
  text-transform: uppercase;
  transition: background 0.2s ease;

  &:hover {
    background: lighten(#5aa012, 5%);
  }
`;

UFSelector.propTypes = {
  ufs: PropTypes.array.isRequired,
  selectedUF: PropTypes.string.isRequired,
  handleCloseUFSelector: PropTypes.func.isRequired,
  handleChangeSelect: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

export default UFSelector;
