import React, { useState, useEffect } from 'react';
import * as S from './styles.js';
import axios from 'axios';
import Accordion from '../accordion';
import env from '../../../../environment';

function Faq() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(env.API_CARROS_FAQ)
      .then(response => setData(response.data.retorno.FAQ))
      .catch(err => console.log(err));
  },[]);

  return (
    <S.Container>
      <S.MainTitle>Dúvidas Frequentes</S.MainTitle>
      {data.length > 0 &&
        <Accordion data={data} />
      }
    </S.Container>
  );
}

export default Faq;

