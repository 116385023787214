import React from 'react';
import * as S from './styles';

export default function FooterFixed({ label = 'Compre aqui, agora!' }) {
  return (
    <S.ContentBox>
      <S.Content>
        <S.Text>{label}</S.Text>
        <S.ActionChat codeAVI="11"/>
      </S.Content>
    </S.ContentBox>
  );
};

