import styled from 'styled-components';
import { mobile, mobileSmall, desktop, desktopLarge } from '../../styles/media-queries';

export const Container = styled.div``;

export const Banner = styled.div`
  background-image: url('${props => props.desktopImage}');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;  
  height: 0;
  padding-bottom: 25%;

  ${mobile} {
    background-image: url('${props => props.mobileImage}');
    padding-bottom: 100%;
  }
`;
