import React from 'react';
import * as S from './styles';
import Desktop from '../../../images/banner-express/banner-express-desktop.png';
import Mobile from '../../../images/banner-express/banner-express-mobile.png';

function BannerExpress({ openModal, planSku }) {
  return(
    <S.Container>
      <S.BannerDesktop src={Desktop} onClick={() => openModal(planSku)}/>
      <S.BannerMobile src={Mobile} onClick={() => openModal(planSku)} />
    </S.Container>
  );
}

export default BannerExpress;
