import styled from 'styled-components';
import { mobile, desktop } from '../../styles/media-queries';
import Price from '../price';
import Button from '../button';

export const Wrapper = styled.div``;

export const Banner = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
  position: relative;
  width: 100%;

  ${mobile} {
    background-color: ${props => props.bgColorMobile};
    background-image: url('${props => props.mobileImage}');
    background-position: center center;
    background-size: contain;
    height: 465px;
    padding: 10px 0 0;
  }

  ${desktop} {
    background-color: ${props => props.bgColorDesktop};
    background-image: url('${props => props.desktopImage}');
    background-position: center;
    height: 350px;
    padding: 20px 0 0;
  }
`;

export const PlanPrice = styled(Price)`
  &.bigPrice {
    p {
      font-size: 24px;
      font-weight: 200;
    }
  }
`;

export const BuyButton = styled(Button)`
  background: #58a20b;
  border-radius: 20px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 15px;
  text-transform: uppercase;
  width: max-content;
  ${mobile} {
    margin-bottom: 20px;
  }
`;

export const TextWrapper = styled.div`
  align-items: center;
  bottom: -130px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0 0;
  position: relative;
  ${mobile} {
    bottom: -255px;
  }
`;

export const Text = styled.p`
  font-size: 12px;
  font-weight: 200;
  margin: 10px 0 15px;
  text-transform: uppercase;

  > strong {
  }
  &.OldPrice {
    font-size: 16px;
    font-weight: 800;
    margin: 0;
    text-transform: unset;
    span {
      text-decoration: line-through;
    }
  }
`;

export const Hint = styled.div`
  color: #484848;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: lighter;

  ${mobile} {
    flex-direction: column;
    margin: 10px 0;
    text-align: center;
  }

  ${desktop} {
    justify-content: center;
    margin: 20px 0 20px 0;

    > p:first-child {
      margin: 0 20px 0 0;
    }
  }
`;
