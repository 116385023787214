import React from 'react';
import styled from 'styled-components';

import { Container } from '../../styles/utils';
import { mobile, desktop } from '../../styles/media-queries';
import UFButton from '../uf-button';
import AccessibilityBar from '../accessibility-bar';

const TopBar = ({ uf, onUFRequest, noBanner }) => (
  <Bar>
    <Container>
      <AccessibilityBar />
      <UFButton uf={uf} onUFRequest={onUFRequest} noBanner={noBanner} />
    </Container>
  </Bar>
);

const Bar = styled.div`
  background: #003263;
  border-bottom: 2px solid #82b9e6;
  height: 37px;
  width: 100%;

  > ${Container} {
    display: flex;
    height: 100%;
    position: relative;

    ${mobile} {
      justify-content: ${props => (props.noBanner ? 'space-between' : 'center')};
    }

    ${desktop} {
      justify-content: space-between;
    }
  }
`;

export default TopBar;
