import React from 'react';
import styled from 'styled-components';

import { Container } from '../../styles/utils';

const InfoPhrases = ({ messages }) => (
  <Wrapper>
    {messages && messages.map((message, index) => <p key={index} dangerouslySetInnerHTML={{ __html: message.text || message }}></p>)}

    <p className='migracao-meutim'>
      Para migrar para planos com valor abaixo ao do seu atual, solicite no site
      <a href='https://meutim.tim.com.br/novo/login?bmctx=8B94D49C137CCFE1FA9512A461AC79DB4FBA69EFB5FA39109654FE9A6E39100A&contextType=external&username=string&enablePersistentLogin=true&contextValue=%2foam&password=secure_string&challenge_url=https%3A%2f%2fmeutim.tim.com.br%2fnovo%2flogin&request_id=1057068190335355743&authn_try_count=0&locale=pt_BR&resource_url=https%253A%252F%252Fmeutim.tim.com.br%252F' target="_blank">
        {' '} Meu TIM {' '}
      </a>
      ou ligue *144.
    </p>
  </Wrapper>
);

const Wrapper = styled(Container)`
  color: #484848;
  font-weight: lighter;
  margin: 0 auto 32px auto;
  text-align: center;
  p {
    margin-bottom: 24px;
  }

  a {
    font-weight: bold;
    color: #003463;
  }
`;

export default InfoPhrases;
