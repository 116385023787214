import styled from 'styled-components';

export const Container = styled.div`
  margin: 32px auto;
  max-width: 1170px;
  width: 100%;
  padding: 0 24px;
`;

export const MainTitle = styled.h1`
  color: #4a4a4a;
  font-size: 22px;
  margin: 8px 0px;
`;

export const Content = styled.div`
  margin: 16px 0px;
`;

export const Title = styled.h2`
  color: #4a4a4a;
  font-size: 18px;
  margin: 8px 0px;
`;

export const Text = styled.p`
  color: #4a4a4a;
  font-size: 16px;
`;
