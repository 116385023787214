import React from 'react';
import styled from 'styled-components';

import Icon from '../icon';

const AccessibilityBar = () => (
  <Bar>
    <Text>Acessibilidade:</Text>

    <List>
      <Item>
        <Button type="button" data-accessibility="font-decrease">
          A-
        </Button>
      </Item>
      <Item>
        <Button type="button" data-accessibility="font-increase">
          A+
        </Button>
      </Item>
      <Item>
        <Button type="button" data-accessibility="contrast">
          <Icon id="icon-contrast" />
        </Button>
      </Item>
      <Item>
        <Button as="a" target="_blank" href="http://www.vlibras.gov.br/">
          <Icon id="icon-hand" />
        </Button>
      </Item>
    </List>
  </Bar>
);

const Bar = styled.div`
  align-items: center;
  height: 35px;
  display: flex;
  width: 214px;
`;

const Text = styled.p`
  color: #fff;
  font-size: 11px;
`;

const List = styled.ul`
  align-items: center;
  display: flex;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 10px 0;
`;

const Item = styled.li`
  align-items: center;
  display: flex;
  overflow: hidden;
  padding: 0 10px;

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.palette.lightBlue};
  }
`;

const Button = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: ${({ theme }) => theme.palette.lightBlue};
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  padding: 0;
  transition: color 0.2s linear;

  &:hover {
    color: #fff;
  }

  > svg {
    display: inline-block;
    fill: ${({ theme }) => theme.palette.lightBlue};
    transition: fill 0.2s linear;
    height: 0.9rem;
    width: 0.9rem;

    &:hover {
      fill: #fff;
    }
  }
`;

export default AccessibilityBar;
