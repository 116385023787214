import React from 'react';
import styled from 'styled-components';

import Regulations from '../regulations';
import { mobile, desktop } from '../../styles/media-queries';

const RegulationsWrapper = ({ regulations, labelText, pageName }) => (
  <Wrapper>
    <Regulations regulations={regulations} labelText={labelText} pageName={pageName} />
  </Wrapper>
);

const Wrapper = styled.div`
  align-items: center;
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  width: 100%;

  ${mobile} {
    height: 192px;
  }

  ${desktop} {
    height: 120px;
  }
`;

export default RegulationsWrapper;
