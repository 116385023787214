import React, { useEffect, useState } from 'react';

import { Container } from '../../styles/utils';

import * as S from './styles';

const BannerPrice = ({ mobileContent, desktopContent, plan, pageName, messages }) => (
  <S.Wrapper>
    {mobileContent && (
      <S.Banner
        mobileImage={mobileContent.banner.imageUrl}
        desktopImage={desktopContent.banner.imageUrl}
        bgColorDesktop={desktopContent.banner.colorFill}
        bgColorMobile={mobileContent.banner.colorFill}>
        <Container>
          {plan && (
            <S.TextWrapper>
              <S.Text className="OldPrice">
                de <span>R${plan.price}</span> por
              </S.Text>
              <S.PlanPrice
                className="bigPrice"
                value={plan.priceDiscount.split(',')[0]}
                cents={plan.priceDiscount.split(',')[1]}
                hasDiscount
              />
              <S.Text>Oferta Exclusiva no Site</S.Text>
              <S.BuyButton
                planSku={plan.sku}
                planInternet={plan.internetTotal}
                openModal={() => MODAL.timPosUnificado({ sku: plan.sku, flow: pageName })}>
                Contratar agora
              </S.BuyButton>
            </S.TextWrapper>
          )}
        </Container>
      </S.Banner>
    )}
    <S.Hint>{messages && messages.map((message, index) => <p key={index}>{message.text}</p>)}</S.Hint>
  </S.Wrapper>
);

export default BannerPrice;
