import React, {useState} from 'react';
import * as S from './styles';
import topSvg from '../../../images/arrow-chevron-top.svg';
import bottomSvg from '../../../images/arrow-chevron-bottom.svg';

export default function Accordion({ data }) {
  const [isActive, setIsActive] = useState(-1);

  return (
    <S.Container>
      {data.map((item, index) => (
        <S.AccordionItem key={index} onClick={() => index === isActive ? setIsActive(-1) : setIsActive(index)}>
          <S.AccordionBox>
            <S.AccordionTitle>
              {item.Titulo}
            </S.AccordionTitle>
            <S.AccordionIcon >
              {isActive === index ? <img src={topSvg} /> : <img src={bottomSvg} />}
            </S.AccordionIcon>
          </S.AccordionBox>
          {isActive === index &&
            <S.AccordionContent dangerouslySetInnerHTML={{__html: item.Conteudo }} />
          }
        </S.AccordionItem>
      ))}
    </S.Container>
  );
}
