import React from 'react';
import styled from 'styled-components';

import Button from '../button';
import { mobile, desktop } from '../../styles/media-queries';
import { Container } from '../../styles/utils';

const PlanCta = () => (
  <Wrapper>
    <Msg>
      <strong>E aí, viu como funcionário Banco do Brasil tem muito mais vantagens na TIM? </strong>
      Então, não perca mais tempo! Clique no botão abaixo e garanta já os benefícios exclusivos que preparamos para você.
    </Msg>
    <BuyButton
      children={'CONTRATAR AGORA'}
      dataAnalyticsSelector={'btn-contratar-pos-bb'}
      openModal={() => MODAL.timPosUnificado({ sku: 'TPTF946', flow: 'bancodobrasil' })}
    />
  </Wrapper>
);

const Wrapper = styled(Container)`
  max-width: 900px;
  margin-top: 30px;
`;

const BuyButton = styled(Button)`
  max-width: 232px;
  border-radius: 20px;
  margin: 20px auto;
  display: block;
`;
const Msg = styled.p`
  font-size: 20px;
  line-height: 26px;
  color: #4a4a4a;
  text-align: center;
`;
export default PlanCta;
