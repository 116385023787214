import styled from 'styled-components';
import { mobile } from '../../styles/media-queries';

export const Container = styled.div`
    margin: 0 auto 40px;
    max-width: 1170px;
    padding: 0 15px;
    position: relative;
    width: 100%;
`;

export const BannerDesktop = styled.img`
    display: block;
    width: 100%;
    cursor: pointer;
    ${mobile} {
      display: none;
    }
`;

export const BannerMobile = styled.img`
    display: none;
    margin: 0 auto;
    max-width: 360px;
    width: 100%;
    cursor: pointer;
    ${mobile} {
      display: block;
    }
`;
