import React from 'react';
import styled from 'styled-components';

import { mobile, desktop } from '../../styles/media-queries';
import { Container } from '../../styles/utils';

const Banner = ({ mobileContent, desktopContent }) => (
  <Wrapper>
    {mobileContent && (
      <BannerStyled
        mobileImage={mobileContent.banner.imageUrl}
        desktopImage={desktopContent.banner.imageUrl}
        bgColorDesktop={desktopContent.banner.colorFill}
        bgColorMobile={mobileContent.banner.colorFill}>
        <Container>
          <Logo />

          <TextWrapper>
            <Title dangerouslySetInnerHTML={{ __html: desktopContent.banner.title }}></Title>
            <Text dangerouslySetInnerHTML={{ __html: desktopContent.banner.message }} />
          </TextWrapper>
        </Container>
      </BannerStyled>
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  ${mobile} {
    height: 200px;
  }

  ${desktop} {
    height: 450px;
  }
`;

const BannerStyled = styled.div`
  background-repeat: no-repeat;
  height: 100%;
  margin: 0 auto;
  width: 100%;

  ${mobile} {
    background-color: ${(props) => props.bgColorMobile};
    background-image: url('${(props) => props.mobileImage}');
    background-position: center center;
    background-size: contain;
    padding: 10px 0 0;
  }

  ${desktop} {
    background-color: ${(props) => props.bgColorDesktop};
    background-image: url('${(props) => props.desktopImage}');
    background-position: center;
    padding: 20px 0 0;
  }
`;

const Logo = styled.h1`
  background: url('/images/logo-tim-black.svg') no-repeat;
  background-size: contain;
  text-indent: -9999px;

  ${mobile} {
    width: 100px;
  }

  ${desktop} {
    height: 97px;
    width: 236px;
  }
`;

const TextWrapper = styled.div`
  color: #fff;
  margin: 20px 0 0;

  ${mobile} {
    display: none;
  }
`;

const Title = styled.h2`
  color: #fff;
  font-size: 20px;
`;

const Text = styled.p`
  font-size: 18px;

  > strong {
  }
`;

export default Banner;
