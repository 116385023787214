import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import arrowImage from './assets/arrow.svg';
import { mobile, desktop } from '../../styles/media-queries';

const Regulations = ({ regulations, defaultOptionText = 'Selecione o documento', labelText, pageName }) => {
  const [isButtonEnabled, changeButtonEnabled] = useState(false);
  const [selected, changeSelected] = useState('');

  useEffect(() => {
    if (selected) {
      changeButtonEnabled(true);
    } else {
      changeButtonEnabled(false);
    }
  });

  return (
    <Wrapper pageName={pageName}>
      {labelText && <Label htmlFor="regulation-select">{labelText}</Label>}

      <Content>
        <SelectWrapper pageName={pageName}>
          <Select id="regulation-select" value={selected} onChange={event => changeSelected(event.target.value)}>
            <option value="">{defaultOptionText}</option>
            {regulations.map((regulation, index) => (
              <option value={regulation.file} key={index}>
                {regulation.text}
              </option>
            ))}
          </Select>
        </SelectWrapper>

        {pageName !== 'familia' && (
          <Button href={selected} target="_blank" enabled={isButtonEnabled}>
            Abrir
          </Button>
        )}

        {pageName === 'familia' && (
          <FamiliaButton href={selected} target="_blank" enabled={isButtonEnabled} data-analytics-selector="download-docs">
            Baixar documento
          </FamiliaButton>
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-items: center;
  color: ${({ pageName }) => (pageName !== 'familia' ? '#2d648d' : '#002e46')};
  display: flex;
  flex-direction: column;
  padding: 24px 32px;

  ${mobile} {
    width: 100%;
  }
`;

const Content = styled.div`

  ${mobile} {
    width: 100%;
  }

  ${desktop} {
    display: flex;
  }
`;

const Label = styled.label`
  font-size: 20px;
  line-height: 1.2;
  margin: 0 0 8px;

  ${mobile} {
    max-width: 280px;
    text-align: center;
  }
`;

const SelectWrapper = styled.div`
  height: 40px;
  position: relative;

  &:before,
  &:after {
    content: '';
    cursor: pointer;
    display: block;
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }

  &:before {
    background: ${({ pageName }) => (pageName !== 'familia' ? '#2d648d' : '#002e46')};
    border-radius: 0 8px 8px 0;
  }

  &:after {
    background: url(${arrowImage}) center center no-repeat;
    transition: transform 0.2s linear;
  }
`;

const Select = styled.select`
  background: #fff;
  border: solid 1px #979797;
  border-radius: 8px;
  color: ${({ theme }) => theme.palette.text};
  font-size: 16px;
  height: 100%;
  outline: none;
  padding: 0 40px 0 0;
  text-indent: 1ch;
  -webkit-appearance: none;
  width: 100%;

  &::-ms-expand {
    display: none;
  }
`;

const Button = styled.a`
  background: ${({ theme }) => theme.palette.button};
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  opacity: ${({ enabled }) => (enabled ? '1' : '0.5')};
  padding: 0 25px;
  pointer-events: ${({ enabled }) => (enabled ? 'auto' : 'none')};
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 0.3s linear;

  ${mobile} {
    display: inline-block;
    margin: 8px 0 0;
    padding: 8px 0;
    text-align: center;
    width: 100%;
  }

  ${desktop} {
    align-items: center;
    display: flex;
    margin: 0 0 0 10px;
  }
`;

const FamiliaButton = styled(Button)`
  background: none;
  border-radius: 20px;
  border: 1px solid #eb0028;
  font-size: 20px;
  color: #eb0028;
  font-weight: lighter;
  text-transform: none;
`;

Regulations.propTypes = {
  regulations: PropTypes.array.isRequired,
  defaultOptionText: PropTypes.string,
  labelText: PropTypes.string,
  pageName: PropTypes.string,
};

export default Regulations;
