import styled from 'styled-components';

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px 20px 60px;
  width: 100%;
`;

export const Icon = styled.img`
`;

export const Text = styled.p`
  color: #000;
  font-family: TIMSans;
  font-size: 22px;
  margin: 10px 20px;
  text-align: center;
`;