import React, { useEffect, useState} from 'react';

import * as S from './styles';

export function ChatButton({ className, codeAVI }) {

  const [isOpen, SetOpen] = useState(false);
  const [isMobile, SetMobile] = useState(false);

  const url = `https://timpospromo.com.br/?avi=${codeAVI}`;
  const target = '_blank';

  useEffect(() => {
    SetMobile((typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1));
  },[]);

  const callAVI = () => {
    if (isMobile) {
      window.open(url, target);
    } else {
      isOpen ? SetOpen(false) : SetOpen(true);
    }
  };

  return (
    <>
      <S.Chat style={{display: isOpen ? 'flex' : 'none'}}>
        <div>
          <iframe
            src={url}
            frameBorder="0"
            allow="geolocation"
            allowFullScreen
          />
        </div>
      </S.Chat>

      <S.Content>
        <S.ChatButton
          className={`${className}`}
          onClick={callAVI}
        />
        <S.Notification style={{display: isOpen ? 'none' : 'flex'}}>
        1
        </S.Notification>
      </S.Content>
    </>

  );
}


