import React from 'react';
import { hasInSvgSprite } from '../svgs';

const Icon = ({ id, alt, className, extension = 'svg', sprite = true, style }) => (
  <>
    {sprite && hasInSvgSprite(id) ? (
      <svg className={className} style={style}>
        <use xlinkHref={`#${id}`} href={`#${id}`} />
      </svg>
    ) : (
      <img className={className} src={`/images/icons/${id}.${extension}`} alt={alt} style={style} />
    )}
  </>
);

export default Icon;
