export function sumInternet(values = []) {
  return (
    values
      .reduce(
        (internetTotal, value) =>
          (internetTotal +=
            value.indexOf('MB') !== -1 ? parseFloat(value.replace(',', '.')) / 1000 : parseFloat(value.replace(',', '.')) || 0),
        0
      )
      .toString()
      .replace('.', ',') + 'GB'
  );
}

export function hyphensToCamelCase(string) {
  return string.replace(/-([a-z])/g, g => g[1].toUpperCase());
}

export function objectPropsIntoArray(properties) {
  return Object.values(properties);
}

export function sortCardsByOrder(cards) {
  if (!cards) return [];
  return cards.sort((a, b) => a.order - b.order);
}
